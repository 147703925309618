import Alert from '@atoms/Alert/Alert';
import { Job } from '@schemas/Job';
import clsx from 'clsx';
import { useMemo } from 'react';

import styles from './GroupAlert.module.scss';

interface Props {
  job: Job;
}

const GroupAlert = ({ job }: Props) => {
  const date = new Date(job.createdAt).toLocaleString();

  const completedAssetJobs = job.totalAssetJobsCount - job.assetJobsPendingCount;
  const bannerMesage = useMemo(() => {
    if (job.status === 'pending') {
      return `At ${date} ${job.groupName} is being processed: ${completedAssetJobs} / ${job.totalAssetJobsCount}`;
    } else if (job.status == 'deploying_textures' || job.status == 'asset_sync_running') {
      return `Finalizing ${job.groupName}.`;
    }
  }, [completedAssetJobs, date, job.groupName, job.status, job.totalAssetJobsCount]);

  const jobFinished = useMemo(() => job.status === 'finished', [job.status]);

  return (
    <>
      {jobFinished ? (
        <div className={clsx('text-center', styles['bg-gradient-completed'])}>
          <Alert color={'transparent'}>
            {`At ${date} ${job.groupName} is fully updated. View it`}
            <a
              href={`/themes/${job.groupId}`}
              className="text-white ps-1 m-auto fw-bolder"
              data-test-id="group_alert_link"
            >
              here.
            </a>
          </Alert>
        </div>
      ) : (
        <div className={clsx('text-center m-0 fw-bold', styles['bg-gradient-pending'])}>
          <Alert color={'transparent'} textColor="tertiary">
            {bannerMesage}
          </Alert>
        </div>
      )}
    </>
  );
};

GroupAlert.displayName = 'GroupAlert';
export default GroupAlert;
