import type { AFError } from '@schemas/AFError';
import type { Job } from '@schemas/Job';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { HTTP_AUTH } from './api';

const jobsUrl = '/api/cafe/v1/job_status';
const intervalMs = 1000 * 60 * 1;

export const useJobs = (): UseQueryResult<Job, AFError> =>
  useQuery(
    'jobs',
    async () => {
      try {
        const { data } = await HTTP_AUTH.get<Job>(jobsUrl);

        return data;
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.data) {
            throw error.response.data as AFError;
          } else {
            throw { errorMessages: [error.message] };
          }
        } else {
          throw { errorMessages: ['An error occurred'] };
        }
      }
    },
    {
      // Refetch the data every 1 min
      refetchInterval: intervalMs,
    },
  );
