import type { AFError } from '@schemas/AFError';
import type { Company } from '@schemas/Company';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { HTTP_AUTH } from './api';

const getCompanyUrl = '/api/cafe/v1/company';
const companiesUrl = '/api/cafe/v1/companies/';

export const useCompany = (): UseQueryResult<Company, AFError> =>
  useQuery('company', async () => {
    try {
      const response = await HTTP_AUTH.get<Company>(getCompanyUrl);
      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  });

export const useCompanies = (id: number | null | undefined): UseQueryResult<Company, AFError> =>
  useQuery('companies', async () => {
    try {
      if (id) {
        const response = await HTTP_AUTH.get<Company[]>(`${companiesUrl}${id}`);
        return response.data;
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  });
