import { PrivateRoute } from '@atoms';
import { LayoutContext } from '@contexts/LayoutContext';
import GroupAlert from '@molecules/GroupAlert/GroupAlert';
import SideBar from '@organisms/SideBar/SideBar';
import TopNavBar from '@organisms/TopNavBar/TopNavBar';
import { useJobs } from 'api/jobs';
import clsx from 'clsx';
import { ReactElement, useMemo, useState } from 'react';

import styles from './NavbarsLayout.module.scss';

type ResourceStatus = 'idle' | 'error' | 'loading' | 'success';

interface Props {
  children: ReactElement;
}

const NavbarsWrapper = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [resourceName, setResourceName] = useState<string | undefined>();
  const [resourceStatus, setResourceStatus] = useState<ResourceStatus | undefined>();

  const { data: job } = useJobs();

  const value = useMemo(
    () => ({
      setResourceName,
      setResourceStatus,
    }),
    [],
  );

  const bannerLifeCicle = () => {
    const now = new Date();
    if (!job) return false;

    const createdDate = new Date(job.createdAt);
    if (createdDate.getTime() + 300000 < now.getTime()) {
      // 5 minutes to miliseconds
      return false;
    }

    return true;
  };

  const checkJob = JSON.stringify(job) !== '{}';

  return (
    <PrivateRoute>
      <LayoutContext.Provider value={value}>
        <div className="d-flex overflow-y-hidden">
          <SideBar isOpen={isOpen} close={() => setIsOpen(false)} />
          <div className="d-flex flex-column w-100 vh-100 z-index-sticky">
            <TopNavBar
              setOpen={setIsOpen}
              resourceName={resourceName}
              resourceStatus={resourceStatus}
            />
            {job && checkJob && bannerLifeCicle() && <GroupAlert job={job} />}
            <main className={clsx(styles['main-container'], 'overflow-auto')}>{children}</main>
          </div>
        </div>
      </LayoutContext.Provider>
    </PrivateRoute>
  );
};

const NavbarsLayout = (page: ReactElement) => <NavbarsWrapper>{page}</NavbarsWrapper>;
export default NavbarsLayout;
