import { createContext } from 'react';

interface LayoutValues {
  setResourceName: (resourceName?: string) => void;
  setResourceStatus: (resourceStatus?: 'idle' | 'error' | 'loading' | 'success') => void;
}

export const LayoutContext = createContext<LayoutValues>({
  setResourceName: () => {
    /* do nothing */
  },
  setResourceStatus: () => {
    /* do nothing */
  },
});
