import { BurgerIcon, LoadingIndicator, NavBarBreadcrumbs, UserTag } from '@atoms';
import { useAuth } from '@hooks/useAuth';
import { SignOutButton } from '@molecules';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { pathParser } from 'utils/PathUtils';

interface Props {
  setOpen: (open: boolean) => void;
  resourceName?: string;
  resourceStatus?: 'idle' | 'error' | 'loading' | 'success';
}

const TopNavBar = ({ setOpen, resourceName, resourceStatus }: Props) => {
  const router = useRouter();
  const paths = pathParser(router.asPath.split('?')[0]);

  const displayedPath = paths.map((path) =>
    path !== '' && resourceName !== undefined && !isNaN(Number(path)) ? resourceName : path,
  );
  const { user } = useAuth();

  return (
    <nav className="navbar navbar-main navbar-expand-lg shadow-none bg-gray-100 py-1">
      <div className="container-fluid py-1 w-100">
        {resourceStatus === 'loading' ? (
          <div className="m-2">
            <LoadingIndicator />
          </div>
        ) : (
          <NavBarBreadcrumbs routesList={paths} routesDisplays={displayedPath} />
        )}

        <div className="d-flex align-items-center">
          <div className="mx-3">
            <Image src="/assets/img/icons/person.svg" alt="user" height={22} width={22} />
          </div>
          <div>
            <UserTag username={user?.username ?? ''} />
            <SignOutButton />
          </div>

          <div className="mx-3">
            <Image src="/assets/img/icons/settings.svg" alt="user" height={22} width={22} />
          </div>
          <div className="mx-3">
            <Image src="/assets/img/icons/alert.svg" alt="user" height={22} width={22} />
          </div>

          <div className="mx-3">
            <button
              data-test-id="button-burger-menu-open"
              className={clsx('d-block d-lg-none border-0 bg-transparent p-0')}
              type="button"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setOpen(true)}
            >
              <BurgerIcon />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default TopNavBar;
